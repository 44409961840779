import React from "react";
import { Link } from "react-router-dom";
function Breadcrumbs({ parent, child }) {
  return (
    <section data-aos="fade-down" className="breadcrumbs">
      <div className="container">
        <div className="d-flex pt-3 justify-content-between align-items-center">
          <h2>{child ? child.name : parent.name}</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            {parent && !child ? (
              <li>{parent.name}</li>
            ) : (
              <li>
                <Link to={parent.link}>{parent.name}</Link>
              </li>
            )}
            {parent.link && child && <li>{child.name}</li>}
          </ol>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumbs;
