export const sendMail = async (templateId, params) => {
  const sendinblue = require("sib-api-v3-sdk");
  const mailClient = sendinblue.ApiClient.instance;
  const apikey = mailClient.authentications["api-key"];
  apikey.apiKey =
    "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";
  const Mailer = new sendinblue.TransactionalEmailsApi();
  let mail = new sendinblue.SendSmtpEmail();
  mail = {
    to: [
      {
        email: "tina@flyingeagleshipping.com",
      },
    ],
    sender: {
      name: "Flying Eagle",
      email: "michaelnwuju213@gmail.com",
    },
    templateId,
    params,
  };

  return await Mailer.sendTransacEmail(mail);
};
