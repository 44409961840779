import React from "react";

function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact">
                <h3>Flying Eagle</h3>
                <p>
                  {" "}
                  Flying Eagle is a custom licensed agent, fully incorporated in
                  Nigeria under the Company and Allied Matters Decree of 1990.
                </p>
              </div>

              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/privacy-policy">Privacy policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 contact-us">
                <h4>Contact Us</h4>
                <p>
                  2, Okanlawon Ajayi Street,
                  <br />
                  Ground Floor, Masha End,
                  <br />
                  Surulere Lagos.
                  <br />
                  <strong>Phone:</strong>{" "}
                  <a className="text-white" href="tel:+2347031551431">
                    +234 703 155 1431
                  </a>
                  <br />
                  <strong>Email:</strong>{" "}
                  <a
                    className="text-white"
                    href="mailto:tina@flyingeagleshipping.com"
                  >
                    tina@flyingeagleshipping.com
                  </a>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright-wrap d-md-flex py-4">
            <div className="me-md-auto text-center text-md-start">
              <div className="copyright">
                &copy; Copyright{" "}
                <strong>
                  <span>Flying Eagle</span>
                </strong>
                . All Rights Reserved
              </div>
              <div className="credits">
                Developed by{" "}
                <a href="https://facebook.com/milexpro213">Milexpro</a>
              </div>
            </div>
            <div className="d-none social-links text-center text-md-right pt-3 pt-md-0">
              <a href="/" className="twitter">
                <i className="bx bxl-twitter"></i>
              </a>
              <a href="/" className="facebook">
                <i className="bx bxl-facebook"></i>
              </a>
              <a href="/" className="instagram">
                <i className="bx bxl-instagram"></i>
              </a>
              <a href="/" className="google-plus">
                <i className="bx bxl-skype"></i>
              </a>
              <a href="/" className="linkedin">
                <i className="bx bxl-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* eslint-disable-next-line */}
      <a
        href="#"
        className="btn back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}

export default Footer;
