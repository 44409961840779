import React, { useState } from "react";
import { Breadcrumbs, useModal } from "../components";
import { sendMail } from "../constants";
function Quote() {
  const Modal = useModal();
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleChange = (e) => {
    setForm((form) => {
      return { ...form, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject, message } = form;
    sendMail(13, { subject, message, name, email }).then(() => {
      Modal.setModal({
        title: "Success!",
        icon: "success",
        text: "We shall respond to you soonest!",
        confirmButtonText: "Got it!",
        showCloseButton: true,
        didClose: () => {},
        confirmAction: () => {},
      });
    });
  };
  return (
    <main id="main">
      <Breadcrumbs parent={{ name: "Contact Us" }} />
      <section className="inner-page">
        <div className="container">
          <div data-aos="fade-up" className="col-12">
            <div className="card shadow-lg">
              <div className="card-header">
                <h2 className="text-center info-title">Contact Us</h2>
              </div>
              <form onSubmit={handleSubmit} className="p-4">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                      value={form.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                      value={form.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                      value={form.subject}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                      value={form.message}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn btn-primary">
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Quote;
