import React from "react";

function Header() {
  return (
    <header data-aos="fade-down" id="header" className="fixed-top shadow-lg">
      <div className="container d-flex align-items-center justify-content-between">
        <a href="/" className="logo">
          <img
            src="assets/img/logo.svg"
            alt="Flying Eagle"
            className="img-fluid"
            style={{ transform: "scale(2)" }}
          />
        </a>

        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a className="nav-link scrollto active" href="/">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="/contact-us">
                Contact Us
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="/privacy-policy">
                Privacy Policy
              </a>
            </li>
            <li>
              <a className="getstarted scrollto" href="/get-quote">
                Get Quote
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
}

export default Header;
