import sweet from "sweetalert2";
import initSwal from "sweetalert2-react-content";
const swal = initSwal(sweet);
function useModal() {
  return Object.freeze({
    setToast: ({ title, icon, position, timer, didClose }) => {
      const Toast = swal.mixin({
        toast: true,
        position: position || "top",
        timer: timer || 3000,
        didClose,
        timerProgressBar: true,
      });
      Toast.fire({
        title,
        icon,
      });
    },
    setModal: ({
      title,
      icon,
      text,
      textTwo,
      confirmButtonText,
      confirmButtonColor,
      didClose,
      showCloseButton,
      confirmAction,
    }) => {
      swal
        .fire({
          title,
          html: textTwo
            ? `<p className="mt-1 mb-1 text-center">${text}</p><p className="mt-1 mb-1 text-center">${textTwo}</p>`
            : `<p className="mt-1 mb-1 text-center">${text}</p>`,
          icon,
          confirmButtonText,
          confirmButtonColor,
          showCloseButton,
          didClose,
        })
        .then((res) => {
          if (res.isConfirmed) {
            confirmAction();
          }
        });
    },
    setPrompt: ({
      title,
      icon,
      text,
      textTwo,
      confirmButtonText,
      denyButtonText,
      confirmAction,
      denyAction,
      didClose,
    }) => {
      swal
        .fire({
          title,
          icon: icon || "question",
          html: textTwo
            ? `<p className="mt-1 mb-1 text-center">${text}</p><p className="mt-1 mb-1 text-center">${textTwo}</p>`
            : `<p className="mt-1 mb-1 text-center">${text}</p>`,
          showDenyButton: true,
          showCloseButton: true,
          confirmButtonText,
          denyButtonText,
          didClose,
        })
        .then((res) => {
          if (res.isDenied) {
            denyAction && denyAction();
          }
          if (res.isConfirmed) {
            confirmAction();
          }
        });
    },
  });
}

export default useModal;
