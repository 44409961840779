import React from "react";
import { Breadcrumbs } from "../components";
function Privacy(props) {
  return (
    <main id="main">
      <Breadcrumbs parent={{ name: "Privacy Policy" }} />
      <section className="inner-page">
        <div className="container">
          <div data-aos="fade-up" className="col-12">
            <div>
              <h2>Who are we?</h2>
              <p>&nbsp;</p>

              <p>
                <strong>Flying Eagle Shipping Limited.&nbsp;</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                Flying Eagle Shipping Limited is a custom licensed agent, fully
                incorporated in Nigeria under the Company and Allied Matters
                Decree of 1990.
              </p>

              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>Reviews</h2>

              <p>&nbsp;</p>
              <p>
                When visitors leave reviews on the site we collect the data
                shown in the review form, and also the visitor’s IP address and
                browser user agent string to help spam detection.
              </p>

              <p>&nbsp;</p>
              <p>
                An anonymized string created from your email address (also
                called a hash) may be provided to the{" "}
                <a
                  href="https://automattic.com/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gravatar service Policy
                </a>{" "}
                to see if you are using it.&nbsp; After approval of your
                comment, your profile picture is visible to the public in the
                context of your comment.
              </p>

              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>Media</h2>

              <p>&nbsp;</p>
              <p>
                If the need arises for you to upload images on the website, you
                should avoid uploading images with embedded location data (EXIF
                GPS) included. Visitors to the website can download and extract
                any location data from images on the website.
              </p>

              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>Cookies</h2>

              <p>&nbsp;</p>
              <p>
                If you sign up for one of our services on our site,&nbsp; you
                may opt-in to saving your name, email address and website in
                cookies. These are for your convenience so that you do not have
                to fill in your details again when you want to order for another
                service again. These cookies will last for one year.
              </p>

              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>How long we retain your data</h2>

              <p>&nbsp;</p>
              <p>
                If you leave a review, the review and its metadata are retained
                indefinitely. This is so we can recognize and approve any
                follow-up reviews automatically instead of holding them in a
                moderation queue.
              </p>

              <p>&nbsp;</p>
              <p>
                For users that register on our website (if any), we also store
                the personal information they provide in their user profile. All
                users can see, edit, or delete their personal information at any
                time (except they cannot change their username). Website
                administrators can also see and edit that information.
              </p>

              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>What rights you have over your data</h2>

              <p>&nbsp;</p>
              <p>
                If you have an account on this site, or have left reviews, you
                can request to receive an exported file of the personal data we
                hold about you, including any data you have provided to us. You
                can also request that we erase any personal data we hold about
                you. This does not include any data we are obliged to keep for
                administrative, legal, or security purposes.
              </p>

              <h2>&nbsp;</h2>
              <h2>Where we send your data</h2>

              <p>&nbsp;</p>
              <p>
                Visitor reviews may be checked through an automated spam
                detection service. This is for our own privacy.
              </p>
              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>How to contact us</h2>
              <p>&nbsp;</p>
              <p>
                You can reach out to us through our&nbsp;
                <a href="/contact-us">Contact Us</a> page.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Privacy;
