import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { sendMail } from "../constants";
import { useModal } from "../components";
function Home() {
  const Modal = useModal();
  const [info, setInfo] = useState(null);
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleSendMessage = (e) => {
    const { name, email, subject, message } = form;
    e.preventDefault();
    sendMail(13, { subject, message, name, email }).then(() => {
      Modal.setModal({
        title: "Success!",
        icon: "success",
        text: "We shall respond to you soonest!",
        confirmButtonText: "Got it!",
        showCloseButton: true,
        didClose: () => {},
        confirmAction: () => {},
      });
    });
  };
  const handleChange = (e) => {
    setForm((form) => {
      return {
        ...form,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Freight Forwarding from tomorrow</h1>
              <h2>
                Flying Eagle is a custom licensed agent, fully incorporated in
                Nigeria under the Company and Allied Matters Decree of 1990.
              </h2>
              <div>
                <a href="/get-quote" className="btn-get-started scrollto">
                  Get Quote
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              <img
                src="../assets/img/hero-img.png"
                className="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="about" className="about">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="../assets/img/about.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                data-aos="fade-right"
              >
                <h3>About Us</h3>
                <p className="fst-italic">
                  We are designed to render highly professional and personalized
                  services in the areas majorly as follows:
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle"></i>International Freight
                    forwarding, Freight Booking and trading
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>
                    Clearing of consignments from both Sea and Airports in West
                    African Sub-Region
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>Cargo consolidation,
                    Warehousing, International trade consultancy, Imports and
                    Exports.
                  </li>
                </ul>
                <a href="/privacy-policy" className="read-more">
                  Read More <i className="bi bi-long-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="counts" className="counts">
          <div className="container">
            <div className="row counters">
              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start={0}
                  data-purecounter-end={232}
                  data-purecounter-duration={1}
                  className="purecounter"
                ></span>
                <p>Clients</p>
              </div>

              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="521"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p>Projects</p>
              </div>

              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="1463"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p>Hours Of Support</p>
              </div>

              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="15"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p>Hard Workers</p>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Services</h2>
              <p>
                We provide a range of associated support and services. Our
                services are tailored to suit our clients' needs and to put them
                in a vantage position to compete and lead in any of their
                sectors.
              </p>
            </div>

            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                      ></path>
                    </svg>
                    <i className="bx bxs-ship"></i>
                  </div>
                  <h4>Clearing and Forwarding</h4>
                  <p>
                    We deliver all consignments promptly and intact. Whether
                    containerized or break bulk, general cargo or liquid cargo.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="icon-box iconbox-orange ">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                      ></path>
                    </svg>
                    <i className="bx bx-home-smile"></i>
                  </div>
                  <h4>WareHousing</h4>
                  <p>
                    Our warehouse is one with trained security personnel,
                    qualified staff to handle your goods and standby laborers.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="icon-box iconbox-pink">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"
                      ></path>
                    </svg>
                    <i className="bi bi-bicycle"></i>
                  </div>
                  <h4>Door-to-Door Delivery</h4>
                  <p>
                    With our team of top-of-the-line professionals, we lift
                    cargo from any part of the world and we will deliver right
                    to the customer's door step!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="features">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Features</h2>
              <p>
                In the entire sphere of Exports and Imports, it is imperative
                that the process of clearing, forwarding and other shipping
                related services are expedited as much as possible. Hence, the
                need of the following solutions
              </p>
            </div>

            <div className="row align-items-lg-center">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i className="bx bx-receipt"></i>
                  <h4>Freight Booking and trading</h4>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i className="bx bx-cube-alt"></i>
                  <h4>Imports and Exports</h4>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i className="bx bx-box"></i>
                  <h4>Cargo consolidation</h4>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bx bx-check-circle"></i>
                  <h4>Tracking</h4>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2 "
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="../assets/img/features.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <p>
                We enjoy the patronage of reputable ship owners, importers and
                Exporters of various commodities all over the world. Our
                excellent record has endeared us to various institutions. Want
                to reach out to us? Contact us Today!
              </p>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i className="bx bx-map"></i>
                  <h3>Our Address</h3>
                  <p>
                    2, Okanlawon Ajayi Street, Ground Floor, Masha End, Surulere
                    Lagos.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>
                    <a
                      className="text-dark"
                      href="mailto:tina@flyingeagleshipping.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      tina@flyingeagleshipping.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <p>
                    <a
                      target="_blank"
                      className="text-dark"
                      href="tel:+2347031551431"
                      rel="noopener noreferrer"
                    >
                      +234 703 155 1431
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <form onSubmit={handleSendMessage} className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                        value={form.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                        value={form.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                      value={form.subject}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                      value={form.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  {info && (
                    <Alert severity={info.type} onClose={() => setInfo(null)}>
                      {info.message}
                    </Alert>
                  )}
                  <div className="text-center pt-3">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
