import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, Quote, Privacy, Contact } from "./pages";
import { Header, Footer } from "./components";
import "./style.css";
function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/contact-us" exact component={Contact} />
        <Route path="/privacy-policy" exact component={Privacy} />
        <Route path="/get-quote" exact component={Quote} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
